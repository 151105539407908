export class Constants {
  public static PROFILE_OPTIONS = [
    {
      icon: "",
      name: "MY_ACCOUNT",
      url: "my-account",
    },
    {
      icon: "",
      name: "EDIT_PROFILE",
      url: "edit-profile",
    },
    {
      icon: "",
      name: "ORDER_HISTORY",
      url: "",
    },
  ];
  public static ROSTER_FILTERS = [
    {
      name: 'A_Z',
      value: 1
    },
    {
      name: 'RANK',
      value: true
    },
    {
      name: 'HALL_OF_FAME',
      value: true
    }
  ];
}
