import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@config/config';
import { QueryBuilder } from '@services/queryBuilder';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}

  get(limit?, page?, where?, queryAdd?) {
    let query = {
      limit,
      page,
      where: where ? JSON.stringify(where) : where,
      ...queryAdd,
    };
    return this.http.get(
      config.FIREBASE_OPTIONS.URL + config.FIREBASE_OPTIONS.apiNewsURL,
      {
        params: QueryBuilder.queryToHttpParams(
          QueryBuilder.verifyObject(query)
        ),
      }
    );
  }

  getById(uid) {
    return this.http.get(
      config.FIREBASE_OPTIONS.URL +
        config.FIREBASE_OPTIONS.apiNewsURL +
        `/${uid}`
    );
  }

  getPromoBanner() {
    return this.http.get(
      config.FIREBASE_OPTIONS.URL +
        config.FIREBASE_OPTIONS.apiPromoBanners +
          "/news"
    )
  }
}
