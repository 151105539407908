import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { config } from "@config/config";
import { QueryBuilder } from "@services/queryBuilder";

@Injectable({
  providedIn: "root",
})

export class TicketService {

  constructor(
    private http: HttpClient
  ) { }

  get(limit?, page?, where?, queryAdd?) {
    let query = {
      limit,
      page,
      where: where? JSON.stringify(where) : where,
      ...queryAdd
    }
    return this.http.get(config.FIREBASE_OPTIONS.URL + config.FIREBASE_OPTIONS.apiTicketsURL, {
      params: QueryBuilder.queryToHttpParams(QueryBuilder.verifyObject(query))
    });
  }

  getById(uid) {
    return this.http.get(config.FIREBASE_OPTIONS.URL + config.FIREBASE_OPTIONS.apiTicketsURL + `/${uid}`);
  }

  getPromoBanner() {
    return this.http.get(
      config.FIREBASE_OPTIONS.URL +
        config.FIREBASE_OPTIONS.apiPromoBanners +
          "/tickets"
    )
  }
}
